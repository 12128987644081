import React, { useEffect, useState } from "react";
// import firebase from "gatsby-plugin-firebase";
// import 'firebase/firestore';
import dayjs from 'dayjs';
import { document } from 'browser-monads';
import { FiArrowLeft, FiRotateCcw, FiX } from "react-icons/fi";
//fonts
import "@fontsource/poppins"
import GoogleMapReact, { fitBounds, latLng2Tile } from 'google-map-react';
// firebase

// icons
import Logo from "../images/icons/logo.svg";
import LogoYellow from '../images/logo-yellow.svg';
import DeliveredIcon from '../images/icons/transaction/delivered.svg'
import DriverFindIcon from '../images/icons/transaction/driver_find.svg'
import DriverFoundIcon from '../images/icons/transaction/driver_found.svg'
import PickupIcon from '../images/icons/transaction/pickup.svg'
import EnrouteIcon from '../images/icons/transaction/enroute.svg'
import { Link, navigate } from "gatsby";
import { FaMapMarkerAlt } from "react-icons/fa";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db, rtdb } from "../helpers/firebase";
import { onValue, ref } from "firebase/database";
import { useObjectVal } from 'react-firebase-hooks/database';
import { useRef } from "react";
import { Helmet } from "react-helmet";


// const db = firebase.firestore();

const LocationPin = (props) => {
  const [show, setShow] = useState(false);
  return (
    <div className="relative" {...props}>
      <FaMapMarkerAlt size={30} className='mt-[-30px] ml-[-15px]' onClick={() => setShow(!show)} />
      {
        show &&
        <div className="card z-[100] block bg-white shadow-2xl absolute bottom-[120%] left-1/2 -translate-x-1/2 w-[250px] p-4 text-base overflow-visible">
          <button onClick={() => setShow(!show)} className="absolute btn btn-circle btn-sm right-[-10px] top-[-10px]"><FiX size={20} /></button>
          {props.location}
        </div>
      }
    </div>
  )
}

const PinbuggyMarker = (props) => {
  const [show, setShow] = useState(false);

  return (
    <div className="relative inline-block w-[40px] h-[40px] bg-gray-900 p-1 rounded-full border-2 mt-[-40px] ml-[-20px] " {...props}>
      <LogoYellow width='auto' height='100%' onClick={() => setShow(!show)} />
      {
        show &&
        <div className="card z-[100] block bg-white shadow-2xl absolute bottom-[120%] left-1/2 -translate-x-1/2 w-[250px] p-4 text-base overflow-visible">
          <button onClick={() => setShow(!show)} className="absolute btn btn-circle btn-sm right-[-10px] top-[-10px]"><FiX size={20} /></button>
          {/* {JSON.stringify(props.data)} */}
          <div className="flex items-center">

            <div className="avatar bg-slate-500 rounded-full mr-2">
              <div className="w-[40px] rounded-full">
                <img src={props.data.user.verification_files.profileImage} />
              </div>
            </div>
            <p className="font-semibold text-lg leading-none">{props.data.user.full_name}</p>
          </div>
          <hr className="my-2" />
          <span className="text-sm">
            Last updated:
            {dayjs(props.data.last_updated).format('dddd hh:mm a')}
          </span>
        </div>
      }
    </div>
  )
}

const IndexPage = () => {
  let params = new URLSearchParams(document.location.search.substring(1));
  // const [data, setData] = useState(JSON.parse(localStorage.getItem(id))?.data);
  const [data, setData] = useState({});
  const [id, setId] = useState(params.get("id") || false);
  const [tripNumber, setTripNUmber] = useState(params.get("trip_number") || false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [dateUpdated, setDateUpdated] = useState();
  const [allowRefresh, setAllowRefresh] = useState(false);
  const [pinbuggyLoc, setPinbuggyLoc] = useState({});
  const [size, setSize] = useState(null);
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(null);
  const mapRef = useRef();

  useEffect(() => {
    if (!id && !tripNumber) return;
    const type = (id && 'id' || tripNumber && 'trip_number') || null;
    getData(id || tripNumber, type);
  }, []);

  useEffect(() => {
    if (!data?.accepted_by) {
      return;
    }

    const resp = ref(rtdb, `/drivers/${data.accepted_by}`);

    return onValue(resp, (snap) => { // <--- return the unsubscriber!
      if (snap.exists()) {
        const data = snap.val()
        // console.log(data)
        setPinbuggyLoc(data)
      }

    });
  }, [data])

  useEffect(() => {

    if (mapRef.current) {
      const size = {
        width: mapRef.current.clientWidth,
        height: mapRef.current.clientHeight,
      };
      setSize(size);
    }

    // console.log(mapRef.current.clientWidth);
  }, [mapRef.current])

  useEffect(() => {
    if (!loading && size !== null) {

      const latLngStops = data?.stops?.map(({ location }) => toLatLng(location));

      // console.log('latLngStops', latLngStops);

      const getBounds = () => {
        const lats = latLngStops?.map(({ lat }) => lat);
        const lngs = latLngStops?.map(({ lng }) => lng);

        // console.log('lats', lats)
        if (!lats || !lngs) return {
          nw: {
            lat: 0,
            lng: 0,
          },
          se: {
            lat: 0,
            lng: 0,
          }
        };

        return {
          ne: {
            lat: Math.max(...lats),
            lng: Math.max(...lngs),
          },
          sw: {
            lat: Math.min(...lats),
            lng: Math.min(...lngs),
          }
        }
      }
      const bounds = getBounds();
      const { center, zoom } = fitBounds(bounds, size);
      setZoom(zoom);
      setCenter(center);
    }

  }, [data, size, loading])




  const getData = (searchParam, type) => {

    setLoading(true);

    // console.log(searchParam);
    // console.log(type);

    if (type && type == 'id') {
      const docRef = doc(db, 'trips', searchParam);
      getDoc(docRef).then(function (doc) {
        if (doc.exists) {
          const currentDateTime = dayjs();
          setDateUpdated(currentDateTime.format('dddd, MMMM DD, YYYY, hh:mm:ss a'));
          setData(doc.data());
          setLoading(false);
          setAllowRefresh(false);


          setTimeout(() => {
            setAllowRefresh(true);
          }, 10000);
        } else {
          console.log("No such document!");
          setLoading(false);
        }
      })
    }

    if (type && type == 'trip_number') {
      const tripRec = collection(db, "trips");
      // console.log('this happened');

      // Create a query against the collection.
      const q = query(tripRec, where("trip_number", "==", searchParam));

      getDocs(q).then((querySnapshot) => {
        // console.log(querySnapshot.empty);

        if (querySnapshot.empty) {
          setLoading(false);
          setData(undefined);

          return;
        }
        querySnapshot.forEach((doc) => {
          // console.log(doc)
          const currentDateTime = dayjs();
          setDateUpdated(currentDateTime.format('dddd, MMMM DD, YYYY, hh:mm:ss a'));
          setData(doc.data());
          setLoading(false);
          setAllowRefresh(false);


          setTimeout(() => {
            setAllowRefresh(true);
          }, 10000);
        });
      })
        .catch((error) => {
          setLoading(false);
          console.log("Error getting documents: ", error);
        });
    }

  }

  const isDriverFound = data?.driver_info?.name;

  // this renders the driver status
  const renderDriverStatus = () => {
    if (isDriverFound) {
      return <TransactionDetail type="driver-found" driverData={{ ...data?.driver_info, accepted_at: data?.accepted_at?.toDate() }} />;
    }
    return (
      <TransactionDetail type="driver-find" />
    );
  };

  const renderDeliveryStatus = () => {
    // if no driver don't show this
    if (!isDriverFound) return;

    const stops = data?.stops;

    // eslint-disable-next-line consistent-return
    return stops.map((stop, index) => {
      if (index !== 0 && !stops[index - 1].is_complete) {
        return;
      }

      return (
        <div key={index}>
          {
            index == 0 ? (stop.is_complete ? (
              <TransactionDetail type="pickup" completedTime={stop?.completed_on.toDate()} />
            ) : (
              <TransactionDetail type="enroute" routeText="Pickup Location" addressText={stop?.address} />
            )
            ) : (stop.is_complete ? (
              <TransactionDetail type="delivered" completedTime={stop?.completed_on.toDate()} />
            ) : (
              <TransactionDetail type="enroute" routeText="Dropoff Point" addressText={stop?.address} />
            )
            )
          }
        </div>
      );
    });
  };

  if (loading) {
    return (
      <div className="flex flex-col h-screen w-screen items-center justify-center">
        <LogoYellow className="mb-2 w-[150px]" />
        <h3 className="text-lg font-bold mb-4">Just a moment...</h3>
      </div>
    )
  }



  const toLatLng = (location) => {

    if (!location?.latitude || !location?.longitude) return ({});
    return ({
      lat: location.latitude,
      lng: location.longitude,
    })
  }

  // state dependent variable
  const noData = data == undefined || Object.keys(data).length < 0;

  const handleNavigate = () => {
    navigate(`/tracker`);
    setTimeout(() => {
      window.location.reload();
    }, 10);
  }

  if (noData) {
    // console.log(data);
    return (
      <div className="flex flex-col h-screen w-screen items-center justify-center">
        <LogoYellow className="mb-2 w-[150px]" />
        <h3 className="text-lg font-bold mb-4">Transaction not found</h3>
        <button onClick={
          () => {
            navigate(`/tracker`);
            setTimeout(() => {
              window.location.reload();
            }, 1);
          }
        } className="btn btn-primary"><FiArrowLeft className="mr-2" />Go Back</button>

      </div>
    )
  }

  // @TODO uncomment me
  if (Object.keys(data).length > 0 && data?.completed_at !== null) {
    return (
      <div className="flex flex-col h-screen w-screen items-center justify-center">
        <LogoYellow className="mb-2 w-[150px]" />
        <h3 className="text-lg font-bold mb-4">Tracker expired</h3>
        <button onClick={handleNavigate} className="btn btn-primary"><FiArrowLeft className="mr-2" />Go Back</button>
      </div>
    )
  }

  return (
    <div className="w-screen h-screen text-black">
      <Helmet>
        <title>Pinbuggy Tracker</title>
      </Helmet>
      <header className="text-center fixed top-0 z-10 bg-white/75 w-full shadow-lg">
        <div className="flex flex-col md:flex-row items-center py-2 px-10 justify-between">
          <div className="flex items-center">
            <Link to="/">

              <Logo className="mr-2 w-[150px]" />
            </Link>
            <h1 className="font-bold text-lg">Tracker</h1>

          </div>
          {
            (id || tripNumber) && Object.keys(data).length > 0 && (
              <div className="relative">
                <div className="absolute bg-gray-300 w-full pin-c z-0" />
                <h2 className="relative inline-flex items-center bg-gray-200 p-2 px-4 z-10 rounded-full font-semibold text-black">
                  {Object.keys(data).length > 0 ? `#${data?.trip_number}` : 'Loading...'}
                  <button onClick={handleNavigate} className="ml-2"><FiX size={20} /></button>
                </h2>
              </div>
            )
          }

          {
            !id && !tripNumber && (
              <section className="flex align-center">
                <div className="container mx-auto flex flex-wrap items-center justify-between border-2 border-gray-500 rounded-full">
                  <div className=" rounded-full bg-white shadow flex w-full">
                    <input
                      value={search}
                      type="text"
                      placeholder="Enter Tracking Number"
                      className=" w-[250px] md:w-[300px] rounded-tl-full rounded-bl-full py-2 px-4 placeholder:text-black"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className="bg-yellow-400 rounded-tr-full rounded-br-full hover:bg-yellow-500 py-2 px-4" onClick={
                      () => {
                        navigate(`/tracker?trip_number=${search}`);
                        setTimeout(() => {
                          window.location.reload();
                        }, 10);
                      }
                    }>
                      <p className="font-semibold text-base uppercase">Track</p>
                    </button>
                  </div>
                </div>
              </section>
            )
          }


        </div>



        {
          (id || tripNumber) && Object.keys(data).length <= 0 && (
            <h1>Transaction Not found</h1>
          )
        }

      </header>

      <div className="min-h-[500px] h-[50vh] md:h-screen w-screen" ref={mapRef}>
        {/* <div className="fixed z-[1000] bg-white">
          <p>center: {JSON.stringify(center)}</p>
          <p>zoom: {JSON.stringify(zoom)}</p>
          <p>trip: {JSON.stringify((id || tripNumber))}</p>
          <p>loading: {JSON.stringify(loading)}</p>
        </div> */}

        {
          center && zoom && (id || tripNumber) && !loading && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyCHkxE1y1JaNsiuQUIeV0V-Pnl46c-ZLp0' }}
              center={center}
              zoom={zoom - 1}
              options={
                {
                  fullscreenControl: false
                }
              }
            >
              {
                data.stops.map((data, i) => {
                  const { location, address } = data;
                  return (
                    <LocationPin
                      key={i}
                      lat={location.latitude}
                      lng={location.longitude}
                      // text="My Marker"
                      location={address}
                    />
                  )
                })
              }
              {
                isDriverFound && (

                  <PinbuggyMarker lat={pinbuggyLoc?.coords?.latitude} lng={pinbuggyLoc?.coords?.longitude} data={pinbuggyLoc} />
                )
              }

            </GoogleMapReact>
          )
        }
      </div>

      <div className="relative md:fixed md:top-[50px] md:card bg-white md:shadow-2xl md:w-[300px] md:m-5">

        {
          (id || tripNumber) && Object.keys(data).length > 0 && (
            <>
              <section className="p-5 leading-none">
                <p className="mb-2">
                  <span className="font-bold text-sm ">

                    Last updated:
                  </span>
                  <br />
                  <small className="text-xs">
                    {dateUpdated}
                  </small>

                </p>
                <button onClick={() => {
                  if (!id && !tripNumber) return;
                  const type = (id && 'id' || tripNumber && 'trip_number') || null;
                  getData(id || tripNumber, type);
                }} className="btn btn-primary btn-sm" disabled={!allowRefresh}>Refresh<FiRotateCcw className="ml-2" /></button>
              </section>
              <section className="relative">
                <div className="absolute bg-black left-[45px] inset-0 mt-[30px] mb-[30px] w-[10px] z-0"
                >
                  <div className="absolute bottom-0 left-1/2 h-[20px] w-[20px] bg-black -translate-x-1/2 translate-y-1/2 rounded-full"></div>

                </div>
                {
                  Object.keys(data).length > 0 && <>
                    {renderDriverStatus()}
                    {renderDeliveryStatus()}
                  </>
                }

              </section>
            </>
          )
        }
      </div>

    </div>
  );
}

const TransactionDetail = ({
  type, driverData, routeText, completedTime, addressText,
}) => {
  const renderIcon = () => {
    switch (type) {
      case 'delivered':
        return (<DeliveredIcon width={69} height={69} />);
      case 'driver-find':
        return (<DriverFindIcon width={69} height={69} />);
      case 'driver-found':
        return (<DriverFoundIcon width={69} height={69} />);
      case 'enroute':
        return (<EnrouteIcon width={69} height={69} />);
      case 'pickup':
        return (<PickupIcon width={69} height={69} />);
      default:
        return '';
    }
  };
  const renderText = () => {
    switch (type) {
      case 'delivered':
        return 'Item delivered';
      case 'driver-find':
        return 'Finding a courier';
      case 'driver-found':
        return 'Courier found';
      case 'enroute':
        return 'En route to';
      case 'pickup':
        return 'Item picked up';
      default:
        return '';
    }
  };

  return (
    <div className="relative flex p-4 z-10">
      <div className="width-69">

        {renderIcon()}
      </div>
      {/* The content */}
      <div className="ml-6">
        <span className="font-bold">
          {renderText()}
          {' '}
          {
            routeText
          }
        </span>

        {
          driverData && (
            <>
              <div>
                {/* <Avatar
                  rounded
                  containerStyle={{
                    marginTop: 2,
                  }}
                  source={{
                    uri: driverData?.avatar_url,
                  }}
                /> */}
                <div>
                  <span>{driverData?.name}</span>
                  {/* <span style={{ flexWrap: 'wrap' }}>Heh</span> */}
                  {/* <span>{driverData?.mobile_number}</span> */}
                </div>
                {/* <Button
                  style={{ borderRadius: 25 }}
                  type="clear"
                  containerStyle={{ borderRadius: 25, alignSelf: 'center' }}
                  icon={() => <Icon size={25} name="phone-forwarded" color={Colors.GREYISH_BLACK} />}
                  onPress={() => Linking.openURL(`tel:${driverData?.mobile_number}`)}
                /> */}

              </div>

              <span>{dayjs(driverData?.accepted_at).format('hh:mm a')}</span>
            </>
          )
        }
        {
          completedTime && <p>{dayjs(completedTime).format('hh:mm a')}</p>
        }
        {
          addressText && (<><br /><span>{addressText}</span></>)
        }
      </div>
    </div>
  );
};

export default IndexPage;
